import { defineNuxtRouteMiddleware, navigateTo } from '#imports'

export default defineNuxtRouteMiddleware(() => {
	const { isLoggedIn } = useAuth()
	const authTokens = useTokenCookie()
	const nuxtApp = useNuxtApp()

	if (isLoggedIn.value || authTokens.value) {
		return navigateTo({ path: nuxtApp.$localePath('/karaoke/') })
	}
})
